export const MAX_DISTANCE = 1500;
export const MAX_GUESSES = 6;
export const IN_PROGRESS = 0;
export const WIN = 1;
export const LOSS = 2;
export const OVERRIDE_COLLEGEDLE = null;
export const COLLEGEDLE_POOL = 138;
export const COLLEGEDLE_OFFSET = 1000;
export const ALERT_TIME_MS = 2000;
export const BAR_FADE_DELAY = 2500;
export const SHARE_MODAL_DELAY = 2000;
export const CONFETTI_MS_DELAY = 500;
export const SPECIAL_MESSAGE_TEXT = "";
